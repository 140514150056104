<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体卡片视图区域 -->
    <el-card class="box-card">
      <el-row>
        <el-col>
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addDialog = true">添加</el-button>
          <el-button type="success" icon="el-icon-refresh-right" @click="update()" >刷新</el-button>
        </el-col>
      </el-row>

      <!-- 下渲染列表区域 -->
      <transition name="el-fade-in-linear">
        <el-table :data="RolesList" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="角色组" prop="DeviceId"></el-table-column>
          <el-table-column label="角色名" prop="DeviceName"></el-table-column>
          <el-table-column label="主要权限" prop="DeviceSecret"></el-table-column>
          <el-table-column label="权限描述" prop="DeviceSecret"></el-table-column>
          <el-table-column label="操作" width="170px">
            <template v-slot="">
              <el-button type="primary" icon="el-icon-setting" @click="editDialog=true">编辑</el-button>
            </template>
          </el-table-column>

        </el-table>
      </transition>

      <!--  添加对话框  -->
      <el-dialog
          title="添加"
          :visible.sync="addDialog"
          width="50%"
          @close="addDialogClosed"
      >
        <!-- 内容主题区域 -->
        <template>
          <el-radio v-model="radio" label="1">超级管理员</el-radio>
          <el-radio v-model="radio" label="2">普通管理员</el-radio>
        </template>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
    <el-button @click="addDialog = false">取 消</el-button>
    <el-button type="primary" @click="addDialog = false">确 定</el-button>
  </span>
      </el-dialog>

      <!--  编辑对话框  -->
      <el-dialog
          title="编辑"
          :visible.sync="editDialog"
          width="50%"
          @close="addDialogClosed"
      >
        <!-- 内容主题区域 -->
        <template>
          <el-radio v-model="radio" label="1">超级管理员</el-radio>
          <el-radio v-model="radio" label="2">普通管理员</el-radio>
        </template>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
    <el-button @click="editDialog = false">取 消</el-button>
    <el-button type="primary" @click="editDialog = false">确 定</el-button>
  </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>


export default {
  name: "Roles",
  data() {
    return {
      RolesList:[
        {
          DeviceId:'admin',
          DeviceName:'admin',
          DeviceSecret:'超级管理员',

        },
        {
          DeviceId:'admin',
          DeviceName:'admin',
          DeviceSecret:'管理员',

        },
        {
          DeviceId:'admin',
          DeviceName:'admin',
          DeviceSecret:'超级管理员',

        },
        {
          DeviceId:'admin',
          DeviceName:'admin',
          DeviceSecret:'管理员',

        }
      ],

      addDialog:false,
      editDialog:false,
      radio:'2',
    }
  },
  created() {
    this.getRolesList()
  },
  methods: {
    getRolesList() {

    },
    //刷新
    update(){
      this.$router.go(0)
      this.getRolesList()
    },
    //监听设备对话框的关闭事件
    addDialogClosed() {

    },
  }
}
</script>

<style scoped>

</style>
